import BusinessApi from "@/services/BusinessApi.service";
import store from "../index.store";

const state = () => ({
  categories: [],
  items: [],
  infos: {},
  images: [],
});

const getters = {
  categories: (state) => state.categories,
  items: (state) => state.items,
  infos: (state) => state.infos,
  images: (state) => state.images,
};
const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setItems(state, items) {
    state.items = items;
  },
  setInfos(state, infos) {
    state.infos = infos;
  },
  setImages(state, images) {
    state.images = images;
  },
};

// actions
const actions = {
  async init({ commit }, callBack) {
    let progress;
    const uploadFunction = (progressEvent) => {
      progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      return progress;
    };
    const onUploadFunction = {
      headers: {},
      onUploadProgress: uploadFunction,
    };

    await BusinessApi.getItems(onUploadFunction).then(({ data }) => {
      commit(
        "setItems",
        data.data
          .filter((d) => d.attributes.category)
          .map((d) => {
            const a = d.attributes;
            let imageUrl = undefined;
            let imageUrlSmall = undefined;
            if (a.image.data) {
              imageUrl = a.image.data.attributes.formats.large.url;
              imageUrlSmall = a.image.data.attributes.formats.small.url;
            }
            return {
              title: {
                fr_FR: a.title_fr_FR,
                en_GB: a.title_en_GB,
                sv_SE: a.title_sv_SE,
                de_DE: a.title_de_DE,
                el_GR: a.title_el_GR,
              },
              imageUrl: imageUrl,
              imageUrlSmall: imageUrlSmall,
              position: a.position,
              price: a.price,
              available: a.available,
              category: a.category.data.id,
              uuid: d.id,
            };
          })
      );
    });

    await BusinessApi.getCategories(onUploadFunction).then(({ data }) => {
      commit(
        "setCategories",
        data.data.map((d) => {
          const a = d.attributes;
          return {
            name: {
              fr_FR: a.fr_FR,
              en_GB: a.en_GB,
              sv_SE: a.sv_SE,
              de_DE: a.de_DE,
              el_GR: a.el_GR,
            },
            position: a.position,
            uuid: d.id,
          };
        })
      );
    });

    await BusinessApi.init(onUploadFunction)
      .then(({ data }) => {
        commit("setInfos", data.info);
        let browserLanguage = data.info.availableLanguages.filter(
          (l) => l.locale === navigator.language.substr(0, 2)
        )[0];
        if (!browserLanguage) {
          browserLanguage = data.language;
        }
        store.commit("system/setLanguage", browserLanguage);
        return BusinessApi.getInstagram().then(({ data }) => {
          commit("setImages", data.data);
        });
      })
      .finally(() => callBack());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
