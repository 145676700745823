import Vue from 'vue'
import Vuex from 'vuex'
import BusinessStore from './modules/business.store'
import LoaderStore from './modules/loader.store'
import CarouselStore from './modules/carousel.store'
import SystemStore from './modules/system.store'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        business: BusinessStore,
        loader: LoaderStore,
        carousel: CarouselStore,
        system: SystemStore
    }
})

export default store