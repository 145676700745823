import Vue from "vue";
import Vuetify from "vuetify/lib";
import Tripadvisor from "@/components/Tripadvisor";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#00a4e0",
        secondary: "#E07400",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        graytext: "#424242",
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      TripAdvisor: {
        component: Tripadvisor,
      },
    },
  },
});
