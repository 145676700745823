<template>
  <cookie-law
    ref="cookieLaw"
    storageName="google-analytics"
    :buttonText="$t('application.cookies.button')"
    @accept="onAccept()"
  >
    <div slot="message">
      <div v-if="!showCustomize">
        <h1>{{ $t("application.cookies.title") }}</h1>
        <p>
          {{ $t("application.cookies.message") }}
          <a @click="showCustomize = !showCustomize" class="primary--text">
            customize
          </a>
        </p>
      </div>
      <v-container v-if="showCustomize" fluid>
        <v-row align="center" justify="space-between">
          <v-switch
            v-model="google"
            @change="googleChanged()"
            label="Cookies for Google:"
          ></v-switch>
        </v-row>
        <v-row align="center" justify="space-between">
          <v-switch
            v-model="facebook"
            @change="facebookChanged()"
            label="Cookies for Facebook:"
          ></v-switch>
        </v-row>
        <v-row>
          <v-col>
            <a @click="showCustomize = !showCustomize" class="primary--text">
              done
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </cookie-law>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueFbCustomerChat from "vue-fb-customer-chat";

export default {
  components: {
    CookieLaw
  },
  beforeMount() {
    this.google = localStorage.getItem("cookie.google");
    this.google = this.google === null ? true : false;

    this.facebook = localStorage.getItem("cookie.facebook");
    this.facebook = this.facebook === null ? true : false;
  },
  data() {
    return {
      google: true,
      facebook: true,
      showCustomize: false
    };
  },
  methods: {
    googleChanged() {
      localStorage.setItem("cookie.google", this.google);
    },
    facebookChanged() {
      localStorage.setItem("cookie.facebook", this.facebook);
    },
    onAccept() {
      if (this.google) {
        this.acceptGoogle();
      }
      if (this.facebook) {
        this.acceptFacebook();
      }
    },
    acceptGoogle() {
      console.log("accepting google");
      Vue.use(VueAnalytics, {
        id: "UA-108808715-1",
        router: this.$router
      });
    },
    acceptFacebook() {
      console.log("accepting facebook");
      Vue.use(VueFbCustomerChat, {
        page_id: 1880417135357179,
        theme_color: "#00a4e0",
        locale: "en_US",
        greeting_dialog_display: "hide",
        logged_in_greeting: "Hi! Welcome in Meltemi, how can we help you?",
        logged_out_greeting: "Hi! Welcome in Meltemi, how can we help you?"
      });
    }
  }
};
</script>
