import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "paper-css/paper.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import store from "./store/index.store";
import router from "./router";
import i18n from "./i18n";
import "flag-icon-css/css/flag-icon.css";
import VueObserveVisibility from "vue-observe-visibility";
import Notifications from "vue-notification";

Vue.use(Notifications);
Vue.config.productionTip = false;
Vue.use(VueObserveVisibility);

const callBack = () => {
  store.dispatch("loader/end");
};
store.dispatch("business/init", callBack);

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
