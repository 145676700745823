import axios from "axios";
let api = axios.create({
  baseURL: "",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let strapi = axios.create({
  baseURL: "https://cms.meltemi-restaurant.eu/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  async init(config) {
    return api.get(`/static/api/init.json`, config);
  },
  getInstagram() {
    return api.get(`/static/api/instagram.json`);
  },
  async getCategories() {
    return strapi.get(`/api/categories`);
  },
  async getItems() {
    return strapi.get(
      `/api/items?populate=*&pagination[page]=1&pagination[pageSize]=10000`
    );
  },
};
