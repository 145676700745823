import Vue from "vue";
import VueRouter from "vue-router";

const Restaurant = () => import("@/views/Restaurant.vue");
const Menu = () => import("@/views/Menu.vue");
const MenuView = () => import("@/views/MenuView.vue");
const MenuCategory = () => import("@/views/MenuCategory.vue");
const MenuCategoryItem = () => import("@/views/MenuCategoryItem.vue");
const TodaysMenu = () => import("@/views/TodaysMenu.vue");
const About = () => import("@/views/About.vue");
const Gallery = () => import("@/views/Gallery.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/restaurant",
      alias: "/",
      component: Restaurant,
    },
    {
      path: "/menu",
      component: Menu,
      children: [
        {
          path: "",
          component: MenuView,
        },
        {
          path: "/menu/:category",
          component: MenuCategory,
        },
        {
          path: "/menu/:category/:item",
          component: MenuCategoryItem,
        },
      ],
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/gallery",
      component: Gallery,
    },
    {
      path: "/thank-you",
      redirect: "/menu",
    },
    {
      path: "/login",
      component: TodaysMenu,
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

export default router;
