<template>
  <v-app id="app">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view :fontSize="changeFontSize"></router-view>
    </transition>
    <Cookies />
  </v-app>
</template>

<script>
import Cookies from "@/components/system/Cookies";

export default {
  name: "App",
  components: { Cookies },
  computed: {
    changeFontSize() {
      let style = document.documentElement.style;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          style.fontSize = "13px";
          return "10px";
        case "sm":
          style.fontSize = "13px";
          return "13px";
        case "md":
          style.fontSize = "14px";
          return "14px";
        case "lg":
          style.fontSize = "16px";
          return "16px";
        case "xl":
          style.fontSize = "16px";
          return "16px";
        default:
          return "14px";
      }
    }
  }
};
</script>

<style lang="scss">
@import "node_modules/animate.css/animate.min";
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro");

$font-stack: Helvetica, sans-serif;
$primary-color: #333;

html {
  font: 16px $font-stack;
  color: $primary-color;
}

span {
  font-size: 1rem;
}
a {
  color: white !important;
}
body {
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
</style>
