import i18n from "../../i18n";

const state = () => ({
  language: null,
  dayMenuDialog: false,
  dayMenuCategory: "a22b7692-d761-4ecd-8650-79051748f4ef",
  cookie: {
    google: localStorage.getItem("cookie.google"),
    facebook: localStorage.getItem("cookie.facebook")
  },
  breadcrumbs: [],
  drawer: false
});

const getters = {
  language: state => state.language,
  dayMenuDialog: state => state.dayMenuDialog,
  dayMenuCategory: state => state.dayMenuCategory,
  cookie: state => state.cookie,
  breadcrumbs: state => state.breadcrumbs,
  drawer: state => state.drawer
};
const mutations = {
  setLanguage(state, language) {
    i18n.locale = language.locale;
    state.language = language;
  },
  setMenuDialog(state, dayMenuDialog) {
    state.dayMenuDialog = dayMenuDialog;
  },
  setCookie(state, cookie) {
    state.cookie = cookie;
    localStorage.setItem("cookie.google", cookie.google);
    localStorage.setItem("cookie.facebook", cookie.facebook);
  },
  setBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
  toogleDrawer(state) {
    state.drawer = !state.drawer;
  },
  setDrawer(state, value) {
    state.drawer = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
