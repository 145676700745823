const state = () => ({
    title: null,
    subtitle: null,
    image: null,
    headerTitle: null,
    headerImage: null
})

const getters = {
    title: (state) => state.title,
    subtitle: (state) => state.subtitle,
    image: (state) => state.image,
    headerTitle: (state) => state.headerTitle,
    headerImage: (state) => state.headerImage,
}
const mutations = {
    setTitle(state, title) {
        state.title = title
    },
    setSubtitle(state, subtitle) {
        state.subtitle = subtitle
    },
    setImage(state, image) {
        state.image = image
    },
    setHeaderTitle(state, headerTitle) {
        state.headerTitle = headerTitle
    },
    setHeaderImage(state, headerImage) {
        state.headerImage = headerImage
    },
}
const actions = {
    setData({ commit }, obj) {
        commit("setTitle", obj.title)
        commit("setSubtitle", obj.subtitle)
        commit("setimage", obj.image)
        commit("setHeaderTitle", obj.headerTitle)
        commit("setHeaderImage", obj.headerImage)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}