const state = () => ({
  loading: true
});

const getters = {
  loading: state => state.loading
};
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  }
};
const actions = {
  start({ commit }) {
    commit("setLoading", true);
  },
  end({ commit }) {
    commit("setLoading", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
